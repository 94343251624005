import React, { useEffect } from 'react';
import { useAuth } from '../AuthContext';

function SalesforceAuth() {
    const { login } = useAuth();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const loginStatus = params.get('login');
        if (loginStatus === 'success') {
            login();  // Update the authentication state to logged in
           // window.history.pushState({}, '', '/uploader');  // Optionally redirect to another route
        }
    }, [login]);

    const handleLogin = () => {
        window.location.href = 'https://opportunity-product.bestinbrands.co/oauth2/auth'; // Redirects to Salesforce login
    };

    return (
        <div>
            <button onClick={handleLogin}>Log in with Salesforce</button>
        </div>
    );
}

export default SalesforceAuth;