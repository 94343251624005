import React, { useEffect, useState } from 'react';
import SalesforceAuth from './components/SalesforceAuth';
import FileUploader from './components/FileUploader';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        // Parse the query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const loginStatus = queryParams.get('login');
        if (loginStatus === 'success') {
            setIsAuthenticated(true);
        }
    }, []);

    // Basic styling
    const appStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        background: '#f7f7f7',
        fontFamily: 'Arial, sans-serif',
    };

    const headerStyle = {
        marginBottom: '20px',
        color: '#333',
    };

    return (
        <div style={appStyle}>
            <h1 style={headerStyle}>Salesforce Integration App</h1>
            {!isAuthenticated ? (
                <SalesforceAuth />
            ) : (
                <div>
                    <h2>Welcome! You are authenticated.</h2>
                    <FileUploader />
                </div>
            )}
        </div>
    );
}

export default App;